import React from "react";
import { Link } from "gatsby";
import Image from "../components/image";

const HPMenu = () => {
  return (
    <div className="top-box" id="menu-box">
      <Link to="/">
        <div className="menu-item lighter-menu-item" id="menu-home">
          Home
          <Image name="home" />
        </div>
      </Link>
      <Link to="/portfolio">
        <div className="menu-item darker-menu-item" id="menu-portfolio">
          Portfolio
          <Image name="portfolio" />
        </div>
      </Link>
      <Link to="/career">
        <div className="menu-item darker-menu-item" id="menu-resume">
          Career
          <Image name="linkedin" />
        </div>
      </Link>
      <Link to="/articles">
        <div className="menu-item lighter-menu-item" id="menu-blog">
          Articles
          <Image name="blog" />
        </div>
      </Link>
      <Link to="/tweets">
        <div className="menu-item lighter-menu-item" id="menu-tweets">
          Tweets
          <Image name="twitter" />
        </div>
      </Link>
      <Link to="/contact">
        <div className="menu-item darker-menu-item" id="menu-contact">
          Contact
          <Image name="contact" />
        </div>
      </Link>
    </div>
  );
};

export default HPMenu;
