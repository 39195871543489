import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import HPMenu from "../components/hpmenu";
import HPName from "../components/hpname";
import HPPhoto from "../components/hpphoto";
import HPStudies from "../components/hpstudies";
import HPPortfolio from "../components/hpportfolio";
import HPToolbox from "../components/hptoolbox";
import HPCareer from "../components/hpcareer";
import SEO from "../components/seo";
import { Animated } from "react-animated-css";

const IndexPage = () => {
  const [shadow, setShadow] = useState("");

  const addShadow = () => {
    setTimeout(() => {
      setShadow("main-shadow");
    }, 1100);
  };

  useEffect(() => {
    addShadow();
  }, []);

  return (
    <Layout>
      <SEO title="Home" />
      <div className={shadow} id="main-container">
        <Animated animationIn="rotateInDownLeft">
          <HPMenu />
        </Animated>
        <Animated animationIn="fadeInDown">
          <HPName />
        </Animated>
        <Animated animationIn="rotateInDownRight">
          <HPPhoto />
        </Animated>
        <div id="studies-wrapper">
          <Animated animationIn="zoomIn">
            <HPStudies />
          </Animated>
        </div>
        <div id="portfolio-wrapper">
          <Animated animationIn="rollIn">
            <HPPortfolio />
          </Animated>
        </div>
        <div id="toolbox-wrapper">
          <Animated animationIn="zoomIn">
            <HPToolbox />
          </Animated>
        </div>
        <div id="career-wrapper">
          <Animated animationIn="zoomIn">
            <HPCareer />
          </Animated>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
