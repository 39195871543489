import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import portfolio from "./portfolio.json";
import Image from "../components/image";

const HPPortfolio = () => {
  const [shownImage, setShownImage] = useState(
    portfolio[Math.floor(Math.random() * portfolio.length)]
  );
  const [animation, setAnimation] = useState(true);

  const handleAnimation = () => {
    setTimeout(() => {
      setAnimation(false);
    }, 26000);
  };

  const loopImages = () => {
    setTimeout(() => {
      let current = portfolio.indexOf(shownImage);
      setAnimation(true);
      if (current === portfolio.length - 1) {
        setShownImage(portfolio[0]);
      } else {
        setShownImage(portfolio[current + 1]);
      }
    }, 29000);
  };

  useEffect(() => {
    handleAnimation();
    loopImages();
    //eslint-disable-next-line
  }, [shownImage]);

  return (
    <div id="portfolio-box">
      <Animated
        animationIn="fadeIn"
        animationInDuration={3000}
        animationOut="fadeOut"
        animationOutDuration={1000}
        isVisible={animation}
      >
        <Image name={shownImage.key + "HP"} />
      </Animated>
    </div>
  );
};

export default HPPortfolio;
