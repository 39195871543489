import React from "react";
import Image from "../components/image";

const HPPhoto = () => {
  return (
    <div id="photo-box">
      <Image name="photo" />
    </div>
  );
};

export default HPPhoto;
