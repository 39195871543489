import React from "react";
import Image from "../components/image";

const HPCareer = () => {
  return (
    <div className="bottom-box" id="career-box">
      <p className="box-title">Career</p>
      <Image name="iot" />
      <Image name="comcast" />
      <Image name="mediamelon" />
      <Image name="vfgroup" />
      <Image name="vfitaly" />
      <Image name="lbi" />
    </div>
  );
};

export default HPCareer;
