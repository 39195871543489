import React from "react";
import { Link } from "gatsby";
import Subtitles from "./subtitles";

const HPName = () => (
  <div className="top-box" id="title-box">
    <div id="title">
      <Link to="/">Stefano Picker</Link>
    </div>
    <div id="subtitle">
      <Subtitles />
    </div>
  </div>
);

export default HPName;
