import React from "react";
import Image from "../components/image";

const HPToolbox = () => {
  return (
    <div className="bottom-box" id="toolbox-box">
      <p className="box-title">Toolbox</p>
      <Image name="html" />
      <Image name="react" />
      <Image name="flutter" />
      <Image name="node" />
      <Image name="scrum" />
      <Image name="lean" />
    </div>
  );
};

export default HPToolbox;
