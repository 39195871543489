import React from "react";
import Image from "../components/image";

const HPStudies = () => {
  return (
    <div className="top-box" id="studies-box">
      <p className="box-title">Studies</p>
      <Image name="penn" />
      <Image name="hult" />
      <Image name="ateneo" />
      <Image name="federico" />
    </div>
  );
};

export default HPStudies;
